import type { NextPage } from 'next';
import { notify } from '@corellium/metal';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import useUser from 'hooks/useUser';
import { Loading } from 'components/Loading';

const Home: NextPage = () => {
  const { push } = useRouter();
  const { user } = useUser();

  useEffect(() => {
    push(user ? '/files' : '/login').catch((error) => {
      const title = error instanceof Error ? error.message : (error as string);
      notify({ title });
    });
  }, [push, user]);

  return <Loading />;
};

export default Home;
