import { LoadingIndicator } from '@corellium/metal';
import type { FC, PropsWithChildren } from 'react';

type LoadingProps = PropsWithChildren<{
  label?: string;
}>;

export const Loading: FC<LoadingProps> = ({ label = 'Loading', children }) => (
  <div className="flex h-screen flex-1 flex-col items-center justify-center">
    <LoadingIndicator label={label} size="md" />
    {children ? <div className="mt-3">{children}</div> : null}
  </div>
);
